// WagmiClientProvider.tsx
"use client";

import React from "react";
import { WagmiProvider } from "wagmi";
import { config } from "../wagmiconfig"; // adjust the import path as needed

interface WagmiClientProviderProps {
    children: React.ReactNode;
}

export default function WagmiClientProvider({ children }: WagmiClientProviderProps) {
    return <WagmiProvider config={config}>{children}</WagmiProvider>;
}
