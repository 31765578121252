"use client";
import { Link } from "@arkham/i18n";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { IoMdArrowForward } from "react-icons/io";
import { isTradeRoute } from "../layout/Background";

export function ClientKYCBanner() {
    const pathname = usePathname();
    const t = useTranslations("KYCBanner");

    const isTradePathname = isTradeRoute(pathname);
    if (!isTradePathname) {
        return null;
    }

    return (
        <div className="bg-blue-a1000 fixed top-12 left-0 z-10 flex w-full justify-center gap-3 px-6 py-2 text-sm leading-none md:top-14">
            <div>{t("readyToStartTrading")}</div>
            <Link href={`${process.env.NEXT_PUBLIC_AUTH_URL!}/identity`} className="flex gap-2 text-blue-800">
                <span className="text-blue-800">{t("getVerifiedNow")}</span>
                <IoMdArrowForward />
            </Link>
        </div>
    );
}
