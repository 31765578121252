"use client";
import { useApiQuery } from "./useApi";

export const useNotificationLastRead = () => {
    const notificationsResp = useApiQuery("/account/notifications", {
        queryParams: {
            limit: 1,
            offset: 0,
        },
        options: {
            refetchInterval: 5000,
        },
    });

    return notificationsResp.data?.find((n) => !n.isRead)?.time ?? 0;
};
