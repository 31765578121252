"use client";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
export function ToolTip() {
    return (
        <Tooltip
            id="tooltip"
            place="bottom"
            clickable={true}
            className="bg-white-100! backdrop-blur-xl!"
            style={{
                zIndex: 9999,
            }}
        />
    );
}
