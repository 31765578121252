"use client";
import { VALID_SETTINGS_TABS } from "@/app/[locale]/(routes)/(responsive)/settings/definitions";
import { SubaccountRow } from "@/app/_components/modals/subaccount/SubaccountRow";
import { countDecimalPlaces } from "@/app/_components/trade/orderform/utils";
import { TruncateText } from "@/app/_components/ui/elements/Truncate";
import { AssetIcon } from "@/app/_components/ui/images/AssetIcon";
import { FormattedNumber } from "@/app/_components/ui/number/FormattedNumber";
import { PriceChange } from "@/app/_components/ui/number/PriceChange";
import { PrivacyNumber } from "@/app/_components/ui/number/PrivacyNumber";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Notification, NotificationType } from "@/app/_hooks/types";
import { apiGet, useApiPost } from "@/app/_hooks/useApi";
import { useCurrentSubaccountTotalUSDValue } from "@/app/_hooks/useBalances";
import { useTickers } from "@/app/_hooks/useFetch";
import { useLogout } from "@/app/_hooks/useLogout";
import { useNotificationLastRead } from "@/app/_hooks/useNotifications";
import { useSubaccount, useSubaccountList } from "@/app/_hooks/useSubaccount";
import { Link } from "@arkham/i18n";
import { Button, PopupContainer, Tabs } from "@arkham/ui-components";
import { useTranslations } from "next-intl";
import { useCallback, useEffect, useRef, useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import { MdKeyboardArrowRight } from "react-icons/md";
import "./Notifications.global.css";

export const TradePopup = ({ closePopup }: { closePopup: () => void }) => {
    const t = useTranslations("TradePopup");
    const { openModal } = useModal();

    return (
        <PopupContainer>
            <RowSection
                header={t("trading")}
                items={[
                    { text: t("spotTrading"), link: "/markets/spot", subtext: t("viewAllSpotTradingPairs") },
                    {
                        text: t("usdtPerps"),
                        link: "/markets/perpetuals",
                        subtext: t("useLeverageToMagnifyProfits"),
                    },

                    {
                        text: t("cryptoDeposit"),
                        subtext: t("makeADepositToStartTrading"),
                        scroll: false,
                        onClick: () => openModal(ModalNames.Deposit, undefined),
                    },
                ]}
                closePopup={closePopup}
            />
            <PairSection closePopup={closePopup} />
        </PopupContainer>
    );
};

interface BaseItem {
    text: string;
    subheader?: string;
}

interface SubItem extends BaseItem {
    type: "internal" | "external";
    link: string;
}

interface MenuItem extends BaseItem {
    submenu?: SubItem[];
    type?: "internal" | "external";
    link?: string;
}

export const MorePopup = ({ closePopup }: { closePopup: () => void }) => {
    const [activeItem, setActiveItem] = useState<string | null>(null);
    const t = useTranslations("MorePopup");

    const items: MenuItem[] = [
        {
            text: t("programs"),
            subheader: t("seeOurProgramsAndEarnRewards"),
            submenu: [
                { text: t("pointsProgram"), subheader: t("earnPointsOnEveryTrade"), type: "internal", link: "/points" },
                {
                    text: t("vipProgram"),
                    subheader: t("exclusiveBenefitsForTopTraders"),
                    type: "internal",
                    link: "/vip",
                },
                {
                    text: t("institutional"),
                    subheader: t("solutionsForProfessionalTraders"),
                    type: "internal",
                    link: "/institutional",
                },
                { text: t("comissions"), subheader: t("earnFromYourNetwork"), type: "internal", link: "/commissions" },
                { text: t("dmm"), subheader: t("designatedMarketMakingProgram"), type: "internal", link: "/dmm" },
                { text: t("verified"), subheader: t("verified"), type: "internal", link: "/verified" },
            ],
        },
        {
            text: t("apiTools"),
            subheader: t("exploreApis"),
            submenu: [
                {
                    text: t("apiDocumentation"),
                    subheader: t("completeApiReferenceGuide"),
                    type: "internal",
                    link: "/docs",
                },
                {
                    text: t("apiLimits"),
                    subheader: t("understandingYourApiAccess"),
                    type: "internal",
                    link: "/limits-api",
                },
                {
                    text: t("intelApi"),
                    subheader: t("accessOurIntelligencePlatform"),
                    type: "external",
                    link: "https://info.arkm.com/api-platform",
                },
            ],
        },
        {
            text: t("info"),
            subheader: t("discoverUpdatesResourcesAndInsights"),
            submenu: [
                {
                    text: t("announcements"),
                    subheader: t("allThingsNewOnArkham"),
                    type: "external",
                    link: "https://info.arkm.com/announcements",
                },
                {
                    text: t("media"),
                    subheader: t("allArkhamMediaPages"),
                    type: "external",
                    link: "https://info.arkm.com/media",
                },
                {
                    text: t("research"),
                    subheader: t("readNewsArticlesGuidesAndMore"),
                    type: "external",
                    link: "https://info.arkm.com/research",
                },
                {
                    text: t("careers"),
                    subheader: t("joinTheArkhamTeam"),
                    type: "external",
                    link: "https://info.arkm.com/careers",
                },
                {
                    text: t("codex"),
                    subheader: t("aGuideToOurPlatformsFeatures"),
                    type: "external",
                    link: "https://codex.arkhamintelligence.com/",
                },
                {
                    text: t("whitepaper"),
                    subheader: t("ourVisionAndTechnologyExplained"),
                    type: "external",
                    link: "https://info.arkm.com/whitepaper",
                },
                {
                    text: t("brandAssets"),
                    subheader: t("downloadOfficialArkhamAssets"),
                    type: "external",
                    link: "https://drive.google.com/drive/folders/1Fx6Gx2OYlR4rwZwentsmkr6TslAvpeWG",
                },
            ],
        },
        {
            text: t("fees"),
            subheader: t("viewOurFeeStructure"),
            type: "internal",
            link: "/fees",
        },
        {
            text: t("indexPrices"),
            subheader: t("viewIndexPricesDetails"),
            type: "internal",
            link: "/index-prices",
        },

        {
            text: t("listings"),
            subheader: t("viewListedAssets"),
            type: "internal",
            link: "/listings",
        },
        {
            text: t("support"),
            subheader: t("getHelpFromOurSupportTeam"),
            type: "internal",
            link: "/support",
        },
    ];

    const renderLinkContent = (text: string, subheader?: string) => (
        <>
            <div className="text-sm">{text}</div>
            {subheader && <div className="text-white-700 text-xs">{subheader}</div>}
        </>
    );

    const renderLink = (item: SubItem | MenuItem, key: string) => {
        const baseClassName = "text-white-25 w-full rounded-sm px-3 py-2.5 text-base no-underline hover-blue";

        if (!item.link || !item.type) return null;

        if (item.type === "internal") {
            return (
                <Link key={key} href={item.link} className={baseClassName} onClick={closePopup}>
                    {renderLinkContent(item.text, item.subheader)}
                </Link>
            );
        }

        return (
            <a
                key={key}
                href={item.link}
                className={baseClassName}
                onClick={closePopup}
                target="_blank"
                rel="noopener noreferrer"
            >
                {renderLinkContent(item.text, item.subheader)}
            </a>
        );
    };

    const renderLeftItem = (item: MenuItem, index: number) => {
        if (item.submenu) {
            return (
                <button
                    key={`left-${item.text}-${index}`}
                    className="text-white-25 hover-blue flex w-full items-center justify-between gap-3 rounded-sm px-3 py-2.5 text-left text-base no-underline"
                    onMouseEnter={() => setActiveItem(item.text)}
                    onMouseLeave={() => setActiveItem(item.text === activeItem ? activeItem : null)}
                    onClick={() => setActiveItem(activeItem === item.text ? null : item.text)}
                >
                    <div>{renderLinkContent(item.text, item.subheader)}</div>
                    <MdKeyboardArrowRight />
                </button>
            );
        }

        return renderLink(item, `left-${item.text}-${index}`);
    };

    const activeMenuItem = items.find((i) => i.text === activeItem && i.submenu);

    return (
        <PopupContainer>
            <div className="flex flex-col gap-2 px-4 py-3">
                <div className="text-white-700 text-base font-semibold">{t("more")}</div>
                {items.map((item, index) => renderLeftItem(item, index))}
            </div>
            {activeMenuItem && (
                <div
                    className="flex flex-col gap-1 px-4 py-2"
                    onMouseEnter={() => setActiveItem(activeMenuItem.text)}
                    onMouseLeave={() => setActiveItem(null)}
                >
                    <div className="text-white-700 text-base font-semibold">{t("info")}</div>
                    {activeMenuItem.submenu!.map((sub, index) => renderLink(sub, `submenu-${sub.text}-${index}`))}
                </div>
            )}
        </PopupContainer>
    );
};

export const WalletPopup = ({ closePopup }: { closePopup: () => void }) => {
    const t = useTranslations("WalletPopup");
    return (
        <PopupContainer className="flex-col">
            <WalletSection />
            <SubaccountSection />
            <RowSection
                header={t("wallet")}
                items={[
                    { text: t("overview"), link: "/wallet/assets" },
                    { text: t("deposits"), link: "/wallet/deposits" },
                    { text: t("withdrawals"), link: "/wallet/withdrawals" },
                    { text: t("transactionHistory"), link: "/wallet/transfers" },
                ]}
                closePopup={closePopup}
            />
        </PopupContainer>
    );
};

export const OrdersPopup = ({ closePopup }: { closePopup: () => void }) => {
    const t = useTranslations("OrdersPopup");
    return (
        <PopupContainer>
            <RowSection
                items={[
                    { text: t("orders"), link: "/history/orders" },
                    { text: t("trades"), link: "/history/trades" },
                ]}
                closePopup={closePopup}
            />
        </PopupContainer>
    );
};

export const SettingsPopup = ({ closePopup }: { closePopup: () => void }) => {
    const t = useTranslations("SettingsTabs");
    const { handleLogout } = useLogout();

    const items = [
        ...VALID_SETTINGS_TABS.map((tab) => ({ text: t(tab), link: `/settings/${tab}` })),
        { text: t("logout"), onClick: handleLogout },
    ];
    return (
        <PopupContainer>
            <RowSection items={items} closePopup={closePopup} />
        </PopupContainer>
    );
};

interface BaseItem {
    text: string;
    subtext?: string;
    scroll?: boolean;
}

interface LinkItem extends BaseItem {
    link: string;
    useAnchor?: boolean;
    onClick?: never;
}

interface ClickableItem extends BaseItem {
    link?: never;
    useAnchor?: never;
    onClick: () => void;
}

type ItemType = LinkItem | ClickableItem;

interface RowSectionProps {
    header?: string;
    items: ItemType[];
    closePopup: () => void;
}

export const RowSection: React.FC<RowSectionProps> = ({ header, items, closePopup }) => {
    const renderItem = (item: ItemType) => {
        const content = (
            <div className="flex flex-col gap-1">
                <span className="text-sm">{item.text}</span>
                {item.subtext && <span className="text-white-700">{item.subtext}</span>}
            </div>
        );

        if ("onClick" in item) {
            return (
                <button
                    className={`hover-blue rounded-sm px-2 py-1.5 text-left no-underline`}
                    key={item.text}
                    onClick={() => {
                        closePopup();
                        item.onClick?.();
                    }}
                >
                    {content}
                </button>
            );
        }

        if (item.useAnchor) {
            return (
                <a
                    href={item.link}
                    className={`hover-blue rounded-sm px-2 py-1.5 text-left no-underline`}
                    key={item.text}
                    onClick={(e: React.MouseEvent) => {
                        closePopup();
                    }}
                >
                    {content}
                </a>
            );
        }

        return (
            <Link
                href={item.link}
                className={`hover-blue rounded-sm px-2 py-1.5 no-underline`}
                key={item.text}
                onClick={(e: React.MouseEvent) => {
                    closePopup();
                }}
                scroll={item.scroll}
            >
                {content}
            </Link>
        );
    };

    return (
        <div className="flex w-[16.5rem] max-w-[16.5rem] flex-col gap-3 px-4 py-3">
            {header && <div className="text-white-700 ml-1 text-base">{header}</div>}
            <div className="flex flex-col gap-2">{items.map((item) => renderItem(item))}</div>
        </div>
    );
};

const PairSection = ({ closePopup }: { closePopup: () => void }) => {
    const { tickers } = useTickers();

    const displayed = tickers
        .filter((pair) => pair.quoteSymbol === "USDT")
        .sort((a, b) => parseFloat(b.usdVolume24h) - parseFloat(a.usdVolume24h))
        .slice(0, 5);

    return (
        <div className="bg-ash-a100 flex w-[16.5rem] max-w-[16.5rem] flex-col gap-2 px-4 py-3">
            {displayed.map((pair) => (
                <Link
                    className={`hover-blue rounded-sm px-2 py-1.5 no-underline`}
                    key={pair.symbol}
                    href={`/trade/${pair.symbol}`}
                    onClick={() => closePopup()}
                >
                    <div className="flex items-center justify-between gap-2">
                        <div className="flex items-center gap-2">
                            <AssetIcon url={pair.baseImageUrl} width={16} height={16} />
                            <span>
                                {pair.baseSymbol}
                                <span className="text-white-700">/{pair.quoteSymbol}</span>
                            </span>
                        </div>
                        <div className="flex flex-col items-end gap-1">
                            <FormattedNumber
                                number={pair.price}
                                options={{
                                    fractionDigits: countDecimalPlaces(pair.minPrice),
                                }}
                            />

                            <PriceChange price={pair.price} price24hAgo={pair.price24hAgo} />
                        </div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

const WalletSection = () => {
    const { balancesTotalUSDValue } = useCurrentSubaccountTotalUSDValue();
    const { subaccountName } = useSubaccount();
    const t = useTranslations("WalletSection");
    return (
        <div className="bg-ash-a100 flex w-[16.5rem] max-w-[16.5rem] flex-col gap-1 px-4 pt-3 pb-0">
            <div className="flex justify-between">
                <div className="text-gray-1100">{t("accountBalance")}</div>
                <TruncateText
                    className="max-w-40 rounded-full border border-blue-700 px-2 py-0.5 text-blue-700"
                    text={subaccountName}
                />
            </div>
            <PrivacyNumber
                className="text-base"
                number={balancesTotalUSDValue}
                options={{ style: "currency", currency: "USD" }}
            />
        </div>
    );
};

const SubaccountSection = () => {
    const { sortedSubaccounts } = useSubaccountList();
    const t = useTranslations("SubaccountSection");

    const { openModal } = useModal();
    return (
        <div className="bg-ash-a100 flex w-[16.5rem] max-w-[16.5rem] flex-col gap-1 px-4 pb-3">
            <div className="mb-3 flex items-center justify-between">
                <div className="text-gray-1100">{t("switchAccount")}</div>
                <button
                    data-testid="header-dropdown-subaccount-see-all"
                    className="flex cursor-pointer items-center gap-1 text-blue-500 hover:text-blue-700"
                    onClick={() => openModal(ModalNames.SubaccountManagement)}
                    type="button"
                >
                    <span>{t("seeAll")}</span>
                    <IoMdArrowForward />
                </button>
            </div>
            <div>
                {sortedSubaccounts.slice(0, 6).map((subaccount) => (
                    <SubaccountRow
                        subaccount={subaccount}
                        key={subaccount.id}
                        data-testid="header-dropdown-subaccountrow"
                    />
                ))}
            </div>
        </div>
    );
};

const formatTimestamp = (unixTimestamp: number) => {
    const date = new Date(unixTimestamp / 1000);
    const now = new Date();

    // Check if the timestamp is for the current day
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
        // Return time in "HH:MM AM/PM" format
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12;
        hours = hours ? hours : 12; // The hour '0' should be '12'
        const strMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
        return `${hours}:${strMinutes} ${ampm}`;
    } else {
        // Return date in "MMM DD" format
        return date.toLocaleDateString(undefined, {
            month: "short",
            day: "numeric",
        });
    }
};

const NOTIFICATION_PAGE_SIZE = 50;

export const NotificationPopup = () => {
    const t = useTranslations("NotificationsSection");
    const [type, setNotificationType] = useState(undefined as NotificationType | undefined);
    const lastReadTime = useNotificationLastRead();
    const { mutate: markReadNotifications } = useApiPost("/account/notifications/read", {}, () => [
        ["/account/notifications"],
    ]);
    const debounceRef = useRef<boolean>(false);
    useEffect(() => {
        let timeout = 0;
        if (lastReadTime > 0 && !debounceRef.current) {
            debounceRef.current = true;
            timeout = setTimeout(() => {
                debounceRef.current = false;
                clearTimeout(timeout);
                markReadNotifications({ lastReadTime });
            }, 3000) as unknown as number;
        }
        return () => {
            debounceRef.current = false;
            clearTimeout(timeout);
        };
    }, [lastReadTime, markReadNotifications]);

    const [error, setError] = useState<string | undefined>(undefined);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const loadNotifications = useCallback(
        async (reset: boolean) => {
            try {
                const res = await apiGet("/account/notifications", {
                    type,
                    limit: NOTIFICATION_PAGE_SIZE,
                    offset: reset ? 0 : notifications.length,
                });
                setNotifications((prev) => (reset ? res : [...prev, ...res]));
                setHasMore(res.length === NOTIFICATION_PAGE_SIZE);
            } catch (e: unknown) {
                setError((e as Error).message);
                setHasMore(false);
            }
        },
        [notifications.length, type],
    );

    useEffect(() => {
        loadNotifications(true);
    }, [type]);

    return (
        <PopupContainer className="h-[30rem] w-[26rem] flex-col p-4 px-5">
            <div className="flex justify-between text-base">
                <div>{t("notifications")}</div>
                <Link href="/settings/notifications" className="text-xs text-blue-600 no-underline">
                    {t("manage")} &gt;
                </Link>
            </div>
            <Tabs
                tabs={[
                    { id: "all", name: t("all") },
                    { id: "announcement", name: t("announcements") },
                    { id: "order", name: t("orders") },
                    { id: "price", name: t("prices") },
                    { id: "margin", name: t("margin") },
                ]}
                tab={type ?? "all"}
                setTab={(tab) => {
                    setNotificationType(tab === "all" ? undefined : (tab as NotificationType));
                }}
            />

            <div className="overflow-y-auto">
                {notifications.map((notification) => (
                    <div key={notification.id} className="flex flex-col gap-3 px-3 py-4">
                        <div className="relative text-base">
                            <span>{notification.title}</span>
                            {!notification.isRead && (
                                <span className="absolute top-1 right-1 h-2 w-2 rounded-full bg-blue-700 p-1" />
                            )}
                        </div>
                        <div className="text-gray-1100 flex justify-between">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: notification.message,
                                }}
                            />
                            <div>{formatTimestamp(notification.time)}</div>
                        </div>
                    </div>
                ))}
                {error}
                {hasMore && (
                    <div className="flex justify-center gap-2 py-3">
                        <Button color="blue" variant="tertiary" size="sm" onClick={() => loadNotifications(false)}>
                            {t("loadMore")}
                        </Button>
                    </div>
                )}
            </div>
        </PopupContainer>
    );
};
