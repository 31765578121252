"use client";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";
import styles from "./Background.module.css";
const blurBackgroundRoutes =
    /^(\/[a-z]{2})?(\/fees|\/index-prices|\/commissions|\/points|\/vip|\/listings|\/institutional|\/verified|\/dmm|\/limits-api|\/dashboard|\/knowledge-base|\/support)$/;

export const isBlurBackgroundRoute = (pathname: string): boolean => {
    return blurBackgroundRoutes.test(pathname);
};

export const backgroundShown = (pathname: string): boolean => {
    return pathname === "/" || /^(\/[a-z]{2})?$/.test(pathname) || /^(\/[a-z]{2})?\/markets/.test(pathname);
};

export const isTradeRoute = (pathname: string): boolean => {
    return /^(\/[a-z]{2})?\/trade\//.test(pathname);
};

export function Background({ children }: { children: ReactNode }) {
    const pathname = usePathname();
    const showBackground = backgroundShown(pathname);
    const isTradePathname = isTradeRoute(pathname);
    const isBlurBackgroundPathname = isBlurBackgroundRoute(pathname);

    const baseClasses = "flex flex-col bg-no-repeat md:pt-14 md:pb-7";
    const bgClasses = isBlurBackgroundPathname
        ? `min-h-screen ${styles.blurBackground}`
        : showBackground
          ? styles.background
          : "";
    const fixedHeightClasses = isTradePathname ? "h-screen md:overflow-hidden" : "";

    return <div className={`${baseClasses} ${bgClasses} ${fixedHeightClasses}`}>{children}</div>;
}
