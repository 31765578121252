"use client";
import { UserInfoResponse } from "@/app/_hooks/types";
import { datadogRum } from "@datadog/browser-rum";
import { useEffect } from "react";

datadogRum.init({
    applicationId: "f5051133-af7d-477a-9faf-a0b8bb28dd03",
    clientToken: "pubf13099fba9124cbb76a582d44fe80de0",
    site: "datadoghq.com",
    service: "web",
    env: process.env.NEXT_PUBLIC_ENV,
    version: process.env.NEXT_PUBLIC_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: "mask-user-input",
    allowedTracingUrls: [{ match: process.env.NEXT_PUBLIC_API_URL!, propagatorTypes: ["tracecontext"] }],
    beforeSend: (event) => {
        if (event.type === "error" && event.error.stack?.includes("chrome-extension://")) {
            return false;
        }
        return true;
    },
});

export function DatadogInit({ user }: { user: UserInfoResponse | null }) {
    useEffect(() => {
        if (!user) return;
        datadogRum.setUser({
            id: "" + user.id,
            name: user.username,
            becameVipAt: user.becameVipAt ?? 0 / 1000,
            kycVerifiedAt: user.kycVerifiedAt ?? 0 / 1000,
            pmm: user.pmm,
            requireMFA: user.requireMFA,
            settings: user.settings,
        });
    }, [user]);
    return null;
}
