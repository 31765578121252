"use client";
import { PriceChange } from "@/app/_components/ui/number/PriceChange";
import { getCurrentYear } from "@/app/_components/ui/utils";
import { ConnectionStatus, useWebsocketClient } from "@/app/_contexts/websocket";
import { useTickers } from "@/app/_hooks/useFetch";
import { Link } from "@arkham/i18n";
import { useTranslations } from "next-intl";
import Marquee from "react-fast-marquee";
import {
    MdCopyright,
    MdOutlineMarkChatUnread,
    MdOutlineSignalCellularAlt,
    MdSignalCellularAlt1Bar,
    MdSignalCellularConnectedNoInternet0Bar,
} from "react-icons/md";

export function shouldShowTickerTape(pathname: string) {
    return ["/", "/markets"].includes(pathname) || pathname.startsWith("/trade");
}

//TODO, reintegrate user settings
export function TickerTape() {
    const t = useTranslations("TickerTape");
    const { connectionStatus, latency } = useWebsocketClient();
    const { tickers } = useTickers();

    // Connection status UI helper
    const getConnectionStatusInfo = () => {
        switch (connectionStatus) {
            case ConnectionStatus.Stable:
                return {
                    text: t("stableConnection"),
                    icon: <MdOutlineSignalCellularAlt className="text-base" />,
                    className: "text-green-700",
                };
            case ConnectionStatus.Unstable:
                return {
                    text: t("unstableConnection"),
                    icon: <MdSignalCellularAlt1Bar className="text-base" />,
                    className: "text-yellow-600",
                };
            case ConnectionStatus.Connecting:
                return {
                    text: t("connecting"),
                    icon: <MdSignalCellularAlt1Bar className="animate-pulse text-base" />,
                    className: "text-blue-600",
                };
            case ConnectionStatus.Disconnected:
            default:
                return {
                    text: t("disconnected"),
                    icon: <MdSignalCellularConnectedNoInternet0Bar className="text-base" />,
                    className: "text-red-600",
                };
        }
    };

    const connectionInfo = getConnectionStatusInfo();
    const latencyText = latency ? `${Math.round(latency)}ms` : "";

    return (
        <div className="border-white-200 text-gray-1100 md:border-slate-blue-600 md:bg-slate-blue-100 relative hidden h-7 w-full border-y text-xs md:fixed md:bottom-0 md:left-0 md:z-50 md:flex md:items-center md:justify-between md:border-b-0">
            <div
                className={`border-white-200 flex flex-none items-center gap-1 border-r px-3 pr-4 whitespace-nowrap ${connectionInfo.className} cursor-pointer md:border-gray-500`}
                data-tooltip-id="tooltip"
                data-tooltip-content={latency ? `${t("latency")}: ${latencyText}` : ""}
                data-tooltip-place="top"
            >
                {connectionInfo.icon}
                <div className="hidden md:inline">{connectionInfo.text}</div>
            </div>
            <Marquee pauseOnHover={true} speed={20} autoFill={true}>
                {tickers.map((item) => (
                    <Link
                        href={`/trade/${item.symbol}`}
                        className="border-white-200 md:border-slate-blue-900 flex gap-2 border-r px-3"
                        key={item.symbol}
                        prefetch={false}
                        tabIndex={-1}
                    >
                        <span>
                            {item.baseSymbol}/{item.quoteSymbol}
                        </span>
                        <PriceChange price={item.price!} price24hAgo={item.price24hAgo!} />
                        <span className="text-gray-1000">{item.price || "N/A"}</span>
                    </Link>
                ))}
            </Marquee>
            <div className="flex h-full items-center">
                <button
                    data-tooltip-id="tooltip"
                    data-tooltip-content={`© ${getCurrentYear()} ${t("copyrightText")}`}
                    data-tooltip-place="bottom"
                    aria-label="Copyright information"
                    className="hover:bg-slate-blue-500 focus:bg-slate-blue-500 md:bg-slate-blue-300 hidden h-full transition-all select-none focus:ring-2 focus:ring-blue-500 focus:outline-hidden md:flex md:flex-none md:cursor-pointer md:items-center md:gap-1 md:border-l md:border-gray-500 md:px-2 md:whitespace-nowrap md:text-inherit"
                >
                    <MdCopyright />
                </button>
                <Link
                    href="/legal/terms-of-service"
                    className="hover:bg-slate-blue-500 md:bg-slate-blue-300 hidden h-full transition-all md:flex md:flex-none md:cursor-pointer md:items-center md:gap-1 md:border-l md:border-gray-500 md:px-3 md:whitespace-nowrap md:text-inherit md:no-underline"
                >
                    {t("termsOfService")}
                </Link>
                <Link
                    href="/legal/privacy-policy"
                    className="hover:bg-slate-blue-500 md:bg-slate-blue-300 hidden h-full transition-all md:flex md:flex-none md:cursor-pointer md:items-center md:gap-1 md:border-l md:border-gray-500 md:px-3 md:whitespace-nowrap md:text-inherit md:no-underline"
                >
                    {t("privacyPolicy")}
                </Link>

                <Link
                    href="/support"
                    className="hover:bg-slate-blue-500 md:bg-slate-blue-300 hidden h-full transition-all md:flex md:flex-none md:cursor-pointer md:items-center md:gap-1 md:border-l md:border-gray-500 md:px-3 md:pl-4 md:whitespace-nowrap md:text-inherit md:no-underline"
                >
                    <MdOutlineMarkChatUnread className="" />
                    {t("support")}
                </Link>
            </div>
        </div>
    );
}
