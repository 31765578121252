"use client";
import { Alert, AlertType } from "@/app/_hooks/types";
import { useApiQuery } from "@/app/_hooks/useApi";
import { useEffect, useState } from "react";
import { FaRegClock } from "react-icons/fa";

interface AlertShown extends Alert {
    shown: boolean;
}

function getTimeDifference(givenTime: Date): string {
    const currentTime = new Date();
    const timeDifference = currentTime.getTime() - givenTime.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    const remainingSeconds = seconds % 60;
    const remainingMinutes = minutes % 60;

    if (seconds < 60) {
        return `${seconds} second${seconds === 1 ? "" : "s"} ago`;
    } else if (minutes < 60) {
        return `${minutes} minute${minutes === 1 ? "" : "s"} and ${remainingSeconds} second${remainingSeconds === 1 ? "" : "s"} ago`;
    } else {
        return `${hours} hour${hours === 1 ? "" : "s"} and ${remainingMinutes} minute${remainingMinutes === 1 ? "" : "s"} ago`;
    }
}

export function Alerts() {
    const alerts = useApiQuery("/public/alerts");
    const [activeAlerts, setActiveAlerts] = useState<AlertShown[]>([]);

    useEffect(() => {
        if (alerts.data) {
            setActiveAlerts(alerts.data?.map((alert) => ({ ...alert, shown: true })));
        }
    }, [alerts.data]);

    const handleCloseAlert = (id: number) => {
        setActiveAlerts(activeAlerts.filter((alert) => alert.id !== id));
    };

    const getAlertColor = (type: string) => {
        switch (type) {
            case AlertType.Critical:
                return "bg-red-600";
            case AlertType.Warning:
                return "bg-orange-800";
            case AlertType.Success:
                return "bg-green-600";
            case AlertType.Info:
                return "bg-blue-600";
            default:
                return "bg-blue-600";
        }
    };

    return (
        <div className="fixed top-3 left-1/2 z-50 flex w-3/5 -translate-x-1/2 transform flex-col gap-2.5">
            {activeAlerts.map((alert) => (
                <div
                    key={alert.id}
                    className={`$flex items-center justify-between rounded-sm p-2 ${getAlertColor(alert.type)}`}
                >
                    <div className="flex-1 text-center text-base font-semibold">{alert.message}</div>
                    <div className="flex items-center gap-2">
                        <FaRegClock
                            className="cursor-pointer"
                            data-tooltip-id="tooltip"
                            data-tooltip-content={`Last updated ${getTimeDifference(new Date(alert.lastUpdated / 1000))}`}
                        />
                        <button
                            className="cursor-pointer border-none bg-none text-lg"
                            onClick={() => handleCloseAlert(alert.id)}
                        >
                            &times;
                        </button>
                    </div>
                </div>
            ))}
        </div>
    );
}
