"use client";
import { useNotificationLastRead } from "@/app/_hooks/useNotifications";
import { RiNotification2Line } from "react-icons/ri";

export function NotificationIcon({ className }: { className?: string }) {
    const hasUnread = useNotificationLastRead() > 0;
    return (
        <div className={`relative flex items-center ${className || ""}`}>
            <RiNotification2Line />
            {hasUnread && <div className="absolute -top-0.5 -right-0.5 rounded-full bg-red-800 p-1" />}
        </div>
    );
}
