"use client";
import { useSearch } from "@/app/_contexts/search";
import { useTranslations } from "next-intl";
import { usePathname } from "next/navigation";
import { BiSearch } from "react-icons/bi";
import { backgroundShown } from "../layout/Background";

export function HeaderSearchBar({ "data-testid": dataTestId }: { "data-testid"?: string }) {
    const t = useTranslations("HeaderSearchBar");
    const { openSearch } = useSearch();
    const pathname = usePathname();
    const transparentBar = backgroundShown(pathname);

    const handleFocus = () => {
        openSearch();
    };

    return (
        <button
            data-testid={dataTestId}
            data-tooltip-id="tooltip"
            data-tooltip-content={"Press / to open search anywhere"}
            data-tooltip-place="bottom"
            type="button"
            className={`bg-white-200 text-gray-1100 flex items-center gap-1.5 rounded-full py-1.5 pr-6 pl-2 text-xs md:rounded-sm ${transparentBar ? "" : "bg-slate-blue-400"}`}
            onClick={handleFocus}
            aria-label="Open search"
        >
            <div className="text-white-900 flex items-center justify-center">
                <BiSearch />
            </div>
            <div className="w-auto lg:w-24 xl:w-28">
                <span className="lg:hidden">{t("search")}</span>
                <span className="hidden lg:inline">{t("searchPlaceholder")}</span>
            </div>
        </button>
    );
}
