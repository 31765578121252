"use client";
import { ModalNames, useModal } from "@/app/_contexts/overlay";
import { Button } from "@arkham/ui-components";
import { useTranslations } from "next-intl";

export function OpenDepositModalButton() {
    const { openModal } = useModal();
    const t = useTranslations("OpenDepositModalButton");
    return (
        <Button
            data-testid="header-deposit-button"
            color="green"
            variant="secondary-alpha"
            className="text-xs"
            onClick={() => openModal(ModalNames.Deposit, undefined)}
            size="sm"
        >
            {t("deposit")}
        </Button>
    );
}
