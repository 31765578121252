"use client";
import { Link } from "@arkham/i18n";
import { Logo } from "@arkham/ui-components";
import { useState } from "react";
export function LogoStopWhenHover() {
    const [logoHovered, setLogoHovered] = useState(false);

    return (
        <Link
            href="/"
            onMouseEnter={() => setLogoHovered(true)}
            onMouseLeave={() => setLogoHovered(false)}
            className="flex h-8 w-8 -translate-x-px transform justify-center text-xs lg:w-24 lg:translate-x-0 lg:translate-y-0"
        >
            <div className="lg:hidden">
                <Logo stopped={!logoHovered} of={"lookingAndBlinkingIcon"} />
            </div>
            <div className="hidden lg:flex">
                <Logo stopped={!logoHovered} of={"lookingAndBlinkingIconStaticWord"} />
            </div>
        </Link>
    );
}
