import(/* webpackMode: "eager", webpackExports: ["HeaderItem","HeaderMenuDropdown"] */ "/app/apps/exchange/app/_components/header/desktop/HeaderMenuItemDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LoginButton"] */ "/app/apps/exchange/app/_components/header/desktop/LoginButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LogoStopWhenHover"] */ "/app/apps/exchange/app/_components/header/desktop/LogoStopWhenHover.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OpenMobileMenu"] */ "/app/apps/exchange/app/_components/header/desktop/OpenMobileMenu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SubaccountName"] */ "/app/apps/exchange/app/_components/header/desktop/SubaccountName.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderSearchBar"] */ "/app/apps/exchange/app/_components/header/HeaderSearchBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientKYCBanner"] */ "/app/apps/exchange/app/_components/header/KYCBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NotificationIcon"] */ "/app/apps/exchange/app/_components/header/NotificationIcon.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OpenDepositModalButton"] */ "/app/apps/exchange/app/_components/header/OpenDepositModalButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/exchange/app/_components/header/Popup.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Alerts"] */ "/app/apps/exchange/app/_components/layout/Alerts.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Background"] */ "/app/apps/exchange/app/_components/layout/Background.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DatadogInit"] */ "/app/apps/exchange/app/_components/layout/DatadogInit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TickerTape"] */ "/app/apps/exchange/app/_components/layout/TickerTape.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToolTip"] */ "/app/apps/exchange/app/_components/ui/elements/ToolTip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/apps/exchange/app/_contexts/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/app/apps/exchange/app/_contexts/overlay.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/exchange/app/_contexts/queryClient.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/exchange/app/_contexts/rainbowkit.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/exchange/app/_contexts/search.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SidebarProvider"] */ "/app/apps/exchange/app/_contexts/sidebar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["StorageProvider"] */ "/app/apps/exchange/app/_contexts/storage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/exchange/app/_contexts/wagmi.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WebsocketProvider"] */ "/app/apps/exchange/app/_contexts/websocket.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/exchange/app/_styles/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Root","Trigger","Value","Icon","Portal","Content","ScrollUpButton","Viewport","Item","ItemText","ScrollDownButton"] */ "/app/node_modules/@radix-ui/react-select/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["HydrationBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/HydrationBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["IsRestoringProvider","useIsRestoring"] */ "/app/node_modules/@tanstack/react-query/build/modern/isRestoring.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@tanstack/react-query/build/modern/QueryClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["QueryErrorResetBoundary","useQueryErrorResetBoundary"] */ "/app/node_modules/@tanstack/react-query/build/modern/QueryErrorResetBoundary.js");
;
import(/* webpackMode: "eager", webpackExports: ["useInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsFetching"] */ "/app/node_modules/@tanstack/react-query/build/modern/useIsFetching.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMutation"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutation.js");
;
import(/* webpackMode: "eager", webpackExports: ["useIsMutating","useMutationState"] */ "/app/node_modules/@tanstack/react-query/build/modern/useMutationState.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseInfiniteQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseInfiniteQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQueries"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQueries.js");
;
import(/* webpackMode: "eager", webpackExports: ["useSuspenseQuery"] */ "/app/node_modules/@tanstack/react-query/build/modern/useSuspenseQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/production/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/production/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/Button.module.css");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/HoverPopup.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/Input.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/LanguageSwitcher.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/Logo.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/Tabs.module.css");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/Toast.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-components/src/Toggle.module.css");
