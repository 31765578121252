"use client";
import { Link } from "@arkham/i18n";
import { HoverPopup } from "@arkham/ui-components";
import { Placement } from "@floating-ui/react";
import React, { CSSProperties, FunctionComponent, forwardRef, useState } from "react";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export const HeaderMenuDropdown: FunctionComponent<{
    title: React.ReactNode;
    arrow?: boolean;
    href?: string;
    Popup: FunctionComponent<{ style?: CSSProperties; closePopup: () => void }>;
    alignment?: number;
    placement?: Placement;
    "data-testid"?: string;
    className?: string;
}> = ({
    title,
    arrow = true,
    href,
    Popup,
    alignment = 0,
    placement = "bottom-start",
    "data-testid": dataTestId,
    className,
}) => {
    const [open, setOpen] = useState(false);

    return (
        <HoverPopup
            open={open}
            onOpenChange={setOpen}
            alignment={alignment}
            placement={placement}
            popup={({ style, closePopup }) => <Popup style={style} closePopup={closePopup} />}
        >
            <HeaderItem
                href={href}
                className={`shrink-0 ${open ? "text-blue-900" : ""} ${className}`}
                data-testid={dataTestId}
            >
                {title}
                {arrow && (
                    <span className="text-normal flex">{open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</span>
                )}
            </HeaderItem>
        </HoverPopup>
    );
};

interface HeaderItemProps {
    href?: string;
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    "data-testid"?: string;
    target?: string;
}

export const HeaderItem = forwardRef<HTMLButtonElement | HTMLAnchorElement, HeaderItemProps>(
    ({ href, children, className = "", onClick, "data-testid": dataTestId, target }, ref) => {
        const baseClasses =
            "flex items-center no-underline select-none py-1.5 text-xs gap-1 hover:text-blue-900 lg:text-sm xl:text-base";
        const finalClasses = `${baseClasses} ${className}`.trim();

        if (href) {
            return (
                <Link
                    ref={ref as React.Ref<HTMLAnchorElement>}
                    href={href}
                    className={finalClasses}
                    onClick={onClick}
                    data-testid={dataTestId}
                    target={target}
                >
                    {children}
                </Link>
            );
        }

        return (
            <button
                ref={ref as React.Ref<HTMLButtonElement>}
                className={finalClasses}
                onClick={onClick}
                data-testid={dataTestId}
                type="button"
            >
                {children}
            </button>
        );
    },
);

HeaderItem.displayName = "HeaderItem";
